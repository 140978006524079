@font-face {
  font-family: "expo-arabic";
  src: url("/src/Theme/fonts/expo-arabic-light.eot");
  src: url("/src/Theme/fonts/expo-arabic-light.eot?#iefix")
      format("embedded-opentype"),
    url("/src/Theme/fonts/expo-arabic-light.woff2") format("woff2"),
    url("/src/Theme/fonts/expo-arabic-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "expo-arabic";
  src: url("/src/Theme/fonts/expo-arabic-book.eot");
  src: url("/src/Theme/fonts/expo-arabic-book.eot?#iefix")
      format("embedded-opentype"),
    url("/src/Theme/fonts/expo-arabic-book.woff2") format("woff2"),
    url("/src/Theme/fonts/expo-arabic-book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "expo-arabic";
  src: url("/src/Theme/fonts/expo-arabic-medium.eot");
  src: url("/src/Theme/fonts/expo-arabic-medium.eot?#iefix")
      format("embedded-opentype"),
    url("/src/Theme/fonts/expo-arabic-medium.woff2") format("woff2"),
    url("/src/Theme/fonts/expo-arabic-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "expo-arabic";
  src: url("/src/Theme/fonts/expo-arabic-semi-bold.eot");
  src: url("/src/Theme/fonts/expo-arabic-semi-bold.eot?#iefix")
      format("embedded-opentype"),
    url("/src/Theme/fonts/expo-arabic-semi-bold.woff2") format("woff2"),
    url("/src/Theme/fonts/expo-arabic-semi-bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "expo-arabic";
  src: url("/src/Theme/fonts/expo-arabic-bold.eot");
  src: url("/src/Theme/fonts/expo-arabic-bold.eot?#iefix")
      format("embedded-opentype"),
    url("/src/Theme/fonts/expo-arabic-bold.woff2") format("woff2"),
    url("/src/Theme/fonts/expo-arabic-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
/* h1 {
  font-weight: 700;
}
h2 {
  font-weight: 600;
}
h3,
h4 {
  font-weight: 500;
}
p {
  font-weight: 400;
}   */
