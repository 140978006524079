.codingItemContainer {
  height: 380px;
  border: 1px solid #eee;
  border-radius: 8px;
  background-color: #fff;
  padding: 36px 52px;
  overflow: hidden;
}

/*/*/

.frameContainer {
  display: flex;
  justify-content: center;
  height: 100%;
  background: #e8eaf6;
  overflow: hidden;

  @media (max-width: 810px) {
    padding: 0px !important;
  }
}
