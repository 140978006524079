.dialogHeader {
  padding: 0px 34px;
  background-color: #fff;
  border-bottom: 1px solid #eee;

  .projectIconContainer {
    width: 110px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 600px) {
      width: 90px;
      height: 80px;
    }
    img {
      max-width: 100%;
      height: auto;
      border-radius: 12px;
      margin: 0px !important;
      padding: 0px !important;
      display: block;
    }
  }

  @media (max-width: 600px) {
    padding: 8px 16px;
  }
}

/*/*/
.dialogImages {
  position: relative;
  span {
    width: 100% !important;
    margin: 0px !important;
    padding: 0px !important;
    display: block !important;
    img {
      width: 100% !important;
      height: auto !important;
      display: block !important;
      margin: 0px !important;
      padding: 0px !important;
    }
  }
  &::after {
    contain: "";
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 1;
    width: 100%;
  }
}

/*/*/

.dialogVideoPlayer {
  background-color: #222;
  cursor: pointer;
}

/*/*/
.closeBtn,
.ScrollDownBtn,
.ScrollTopBtn,
.ScrollToPrototype,
.ScrollToVideoPlayer {
  position: fixed !important;
  right: 0 !important;
  border-radius: 100% !important;
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: none !important;
  z-index: 10 !important;

  @media (max-width: 600px) {
    width: 30px !important;
    height: 30px !important;
    background-color: rgba(0, 0, 0, 0.4) !important;
  }
}
.closeBtn {
  top: 0 !important;
  margin: 20px 0px 0px 0px !important;
}
.ScrollDownBtn,
.ScrollTopBtn,
.ScrollToPrototype {
  bottom: 0 !important;
  margin: 0px 0px 20px 0px !important;
  display: none !important;
  @media (min-width: 1281px) {
    display: block !important;
  }
}

.ScrollTopBtn {
  margin: 0px 0px 70px 0px !important;
}
.ScrollToPrototype {
  margin: 0px 0px 130px 0px !important;
  background-color: #fb5b0f !important;
  .swipeIcon {
    animation: fadeAnimated 0.8s infinite alternate;
  }
}

.ScrollToVideoPlayer {
  margin: 0px 0px 130px 0px !important;
  background-color: red !important ;
  bottom: 0 !important;
  .videoIcon {
    animation: fadeAnimated 0.8s infinite alternate;
  }
}

.closeBtn,
.ScrollDownBtn,
.ScrollTopBtn,
.ScrollToPrototype,
.ScrollToVideoPlayer {
  @media (min-width: 1366px) {
    margin-right: 5px !important;
  }

  @media (min-width: 1367px) {
    margin-right: 15px !important;
  }
  @media (min-width: 1500px) {
    margin-right: 35px !important;
  }
  @media (max-width: 1200px) {
    margin: 12px !important;
  }
}

/*/*/
.callDataContainer {
  background-color: #fff;
  padding: 50px 10px;
  .myLogo {
    text-align: center;
    padding: 0px 0px 30px 0px;
  }
  .contactsMethods {
    display: flex;
    justify-content: center;
  }
}

/*/*/
.protoType {
  padding: 60px 10px;
  background-color: #e8eaf6;
  display: none;
  justify-content: center;
  @media (min-width: 810px) {
    display: flex;
  }
  .frameArticle {
    width: 320px;
    @media (max-width: 810px) {
      width: 260px;
    }
  }

  .frameContainer {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    position: relative;
    .frameOverlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      cursor: pointer;
      font-size: 5rem;
    }
  }
}

/*/*/

@keyframes fadeAnimated {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeAnimated {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
