.WebsitesHomeCarouselContainer {
  display: block;
  position: relative;

  .owl-prev,
  .owl-next {
    margin-top: -24px !important;
  }

  @media (max-width: 600px) {
    display: none;
  }
}

/*/---------- Responcive Cards container------------------*/

.WebsitesResponcivePreview {
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
}
