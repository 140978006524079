.iconCardContainer {
  border-radius: 10px;
  border: 1px solid #e8eaf6;
  overflow: hidden;
  width: 100%;
  text-align: center;
  display: block;
  padding: 25px 0px;

  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e8eaf6;
  }
}

.iconImg {
  width: auto !important;
  max-width: 110px !important;
  max-height: 100px !important;
  display: block;
  margin: 0px auto !important;
  border-radius: 12px;
}
