.cloudContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: left;
  z-index: -1;
  overflow: hidden;
  height: 150px;
}
