.audioEvaluationConatiner {
  padding: 50px 0px 35px 0px;
  border-bottom: 1px solid #cad1d6;

  .aduioRightArrow,
  .aduioLeftArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .aduioRightArrow {
    right: 0;
  }
  .aduioLeftArrow {
    left: 0;
  }
}

/*/*/
.AudioItemContainer {
  background-color: #fff;
  border-radius: 10px;
  height: 78px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 10%);
  margin-bottom: 6px;
  padding: 12px 17px;

  .playerButtonsContainer {
    position: relative;
    width: 34px;
    height: 24px;
    button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
