.LatestWorksContainer {
  height: 471px;
  border-radius: 12px;
  background-color: #ffe082;
  position: relative;

  .latestTitle {
    position: absolute;
    top: 0;
    right: 0;
    margin: 61px 46px;
    padding: 0px;

    span {
      height: 1px;
      width: 22px;
      background: #fb5b0f;
      display: block;
      margin: 7px 2px 0px 0px;
    }
  }

  .owl-dots {
    position: absolute !important;
    bottom: 0;
    right: 0;
    margin: 0px 46px 40px 0px;
  }

  // latestWorkItem
  .latestWorkItem {
    text-align: right;
    height: 471px;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0px 46px 0px 10px;
    transition: background-color 0.3s ease;
    img {
      width: auto !important;
    }

    .titleLine {
      height: 2px;
      width: 0px;
      background-color: #fb5b0f;
      display: block;
      margin: 6px 3px 14px 0px;
      transition: all 0.3s ease;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.03);

      .titleLine {
        width: 100px;
      }
    }
  }
}
