.CoverCardContainer {
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  //   transition: all 0.2s ease;
  .centerBox {
    transition: all 0.3s ease;
    position: relative;
    z-index: 3;

    .mobileCoverBox {
      width: 325px;
      height: 375px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      background: url("../../../assets/images/posterBG.png") center center
        no-repeat;

      div {
        background-color: antiquewhite;
        width: 213px;
        height: 353px;
        border-top-left-radius: 21px;
        border-top-right-radius: 21px;
        overflow: hidden;
        span {
          width: 100% !important;
          img {
            width: 100% !important;
            height: auto;
          }
        }
      }
    }

    // ----

    .websiteCoverBox {
      width: 551px;
      height: 424px;
      background-color: #fff;

      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      overflow: hidden;
      box-shadow: 0px -2px 23px rgba(0, 0, 0, 10%);
      span {
        width: 100% !important;

        img {
          width: 100% !important;
          height: auto !important;
        }
      }

      @media (max-width: 1199px) {
        width: 400px;
        height: 424px;

        span {
          width: 100% !important;
          height: 100% !important;
          img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover;
          }
        }
      }
      @media (max-width: 899px) {
        width: 551px;
        height: 424px;

        span {
          width: 100% !important;

          img {
            width: 100% !important;
            height: auto !important;
          }
        }
      }
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s ease;
  }

  .eyeIcon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    margin-top: 70px;
    z-index: 2;
    transition: all 0.3s ease;
    opacity: 0;
    background: rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 100%;
    color: #fff;
  }

  &:hover {
    &::after {
      background-color: rgba(0, 0, 0, 0.2);
    }
    .centerBox {
      margin-bottom: -18px;
    }

    .eyeIcon {
      margin-top: 25px;
      opacity: 1;
    }
  }
}

/*/*/
