.FrontPosterCardContainer {
  border: 1px solid #e8eaf6;
  border-radius: 8px;

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .dataSide {
    width: calc(100% - 313px);
    padding: 31px;

    @media (max-width: 1199px) {
      width: calc(100% - 0px) !important;
    }
  }
  .posterSide {
    background-color: #e8eaf6;
    width: 313px;
    min-height: 381px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    span {
      width: 252px;
      height: 329px;
      background-color: #fff;
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 0px -2px 23px rgba(0, 0, 0, 10%);
      span {
        width: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    &:hover {
      background-color: #e3e5ef;
    }

    @media (max-width: 1199px) {
      display: none;
    }
  }
}
