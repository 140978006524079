.totalBarContainer {
  display: flex;
  margin: 20px 4px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  div {
    height: 1px;
    width: 100%;
    background-color: #e8eaf6;
  }
  p {
    position: absolute;
    right: 0;
    background-color: #fff;
    padding-left: 10px;
  }
}
