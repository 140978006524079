.SectionTitleContainer {
  margin-bottom: 27px;
  @media (max-width: 600px) {
    background-color: #ffe082;
    border-radius: 4px;
    padding: 0px 10px 0px 0px;
    margin-bottom: 21px;
    h2 {
      font-size: 1.2rem !important;
    }

    .plusIcon {
      background-color: transparent !important;
      border: none;
    }
  }
}

/*/*/
.liveicon {
  background-color: #fb5b0f;
  color: #fff;
  padding: 0px 10px 0px 15px;
  border-radius: 3px;
  height: 21px;
  font-size: 0.8rem;
  position: relative;
  margin-top: 8px !important;
  margin-right: 10px !important;
  @media (max-width: 600px) {
    margin-top: 0px !important;
    margin-right: 14px !important;
  }
  @media (max-width: 413px) {
    margin-top: 0px !important;
    margin-right: 9px !important;
  }
  @media (max-width: 360px) {
    display: none;
  }
}
i {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #fff;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 7px 0px 0px 5px;
  animation: liveicon 0.8s infinite alternate;
}

@keyframes liveicon {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
