.skillsSectionConatiner {
  padding-bottom: 89px;
  margin-top: 47px;

  @media (max-width: 600px) {
    background-color: #ffe082;
    padding-bottom: 0px;
    margin-top: 37px;
  }

  .skillsConatiner {
    padding: 60px 66px;
    background-color: #ffe082;
    border-radius: 8px;
    @media (max-width: 600px) {
      padding: 40px 0px;
    }
  }

  .skillsTitle {
    margin-bottom: 33px;
    @media (max-width: 600px) {
      h2 {
        font-size: 1.3rem !important;
      }
    }
  }

  .skillsBoxItem {
    border-radius: 10px;
    padding-top: 18px;
    height: 107px;
    background-color: #fff;
    position: relative !important;
    text-align: center;
    img {
      width: 40px;
      height: 40px;
      border-radius: 10px;
      display: block;
      margin: 0 auto;
    }

    .skillsLevel {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      margin: 3px;
      background-color: #fb5b0f;
      border-radius: 4px;
      p {
        color: #fff !important;
        padding: 2px 6px;
      }
    }
  }
}

/*/*/

.skCounterContainer {
  display: flex;
  flex-wrap: wrap;
  text-align: center;

  .skCounterItem {
    padding: 25px 0px;
    width: 50% !important;
    h3 {
      padding-bottom: 12px !important;
      @media (max-width: 600px) {
        font-size: 1.25rem;
      }
    }
    p {
      font-size: 0.75rem !important;
    }
    &:nth-of-type(1) {
      border-bottom: 1px solid #dec169;
    }
    &:nth-of-type(2) {
      border-right: 1px solid #dec169;
      border-bottom: 1px solid #dec169;
    }
    &:nth-of-type(4) {
      border-right: 1px solid #dec169;
    }
  }

  @media (max-width: 600px) {
    border: 1px solid #dec169;
    border-radius: 8px;
  }
}
