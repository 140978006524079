.bottomMenuContainer {
  background-color: #fff;
  width: 100%;
  height: 57px;
  display: none;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 10;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  // box-shadow: 0px -1px 12px rgba(0, 0, 0, 19%);
  box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 4px;

  @media (max-width: 600px) {
    display: flex;
  }
}
