html,
body {
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  text-align: right;
  direction: rtl;
  font-family: "expo-arabic", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  /* Remove bulrry effect in chrome */
  image-rendering: -webkit-optimize-contrast !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  image-rendering: -webkit-optimize-contrast !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  transform: none !important;
  -moz-transform: none !important;
  -webkit-transform: none !important;
}

/*====== Scrollbar Style ===== 
* {
  --scrollbarBG: #fff;
  --thumbBG: #90a4ae;
}
*::-webkit-scrollbar {
  width: 10px;
}
* {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
*::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

*/

/* Scroll
============================*/
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 3px;
  background-color: #90a4ae;
  width: 2px;
  -webkit-box-shadow: none;
}
::-webkit-scrollbar-thumb:window-inactive {
  background-color: #90a4ae;
  width: 2px;
}
::-webkit-scrollbar {
  width: 3px;
}
::-moz-scrollbar-track {
  -webkit-box-shadow: none;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background: transparent;
}
::-moz-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-color: #90a4ae;
  width: 2px;
  -webkit-box-shadow: none;
}
::-moz-scrollbar-thumb:window-inactive {
  background-color: #90a4ae;
  width: 2px;
}

/* Custom MUI Container ------------------------------------------ */
.MuiContainer-root {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
@media (min-width: 1281px) {
  .MuiContainer-fixed {
    min-width: 1264px !important;
  }
}
@media (min-width: 1367px) {
  .MuiContainer-fixed {
    min-width: 1340px !important;
  }
}

/*/*/
/* .MuiOutlinedInput-root {
  background-color: #555 !important;
  border-radius: 10px !important;
} */

.keys {
  background: #e8eaf6;
  padding: 51px 0px 0px 0px;
  display: block;
}
.keys .customContainer > h1,
.customContainer > h2,
.customContainer > a {
  font-size: 0.72rem;
  font-weight: normal;
  display: inline-flex;
  color: #3b3b3b;
  padding: 7px 12px;
  background-color: #d8dae5;
  border-radius: 4px;
  margin: 0px 0px 6px 0px;
}

.customContainer {
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
  max-width: 100%;
}

.copyRights {
  padding: 51px 6px 60px 6px;
  text-align: center;
  font-size: 0.72rem;
  font-weight: normal;
  background: #e8eaf6;
  margin: 0px;
  color: #3b3b3b;
}

@media (max-width: 599px) {
  .copyRights {
    padding: 40px 6px 90px 6px;
  }
  .keys {
    padding-top: 0px;
  }
}
@media (min-width: 1281px) {
  .customContainer {
    width: 1264px !important;
  }
}
@media (min-width: 1367px) {
  .customContainer {
    width: 1340px !important;
  }
}

/*/owl-nav ----------------------------------------------------*/
.owl-nav [class*="owl-"] {
  width: 40px;
  height: 40px;
  background-color: #fff !important;
  border-radius: 100% !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: all 0.3s ease;
}

.owl-prev,
.owl-next {
  position: absolute;
  top: 50%;
  z-index: 6;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}
.owl-prev {
  right: 0;
  background: url(../assets/images/audio_right.svg) center center no-repeat !important;
  transition: all 0.3s ease;
}
.owl-next {
  left: 0;
  background: url(../assets/images/audio_left.svg) center center no-repeat !important;
  transition: all 0.3s ease;
}

.owl-theme .owl-nav .disabled {
  opacity: 0 !important;
}
/* hover ---------------------------------------------- */
.owl-theme:hover .owl-nav [class*="owl-"] {
  transition: all 0.3s ease;
  opacity: 1;
}
.owl-theme:hover .owl-prev {
  right: -26px !important;
  transition: all 0.3s ease;
}
.owl-theme:hover .owl-next {
  left: -26px !important;
  transition: all 0.3s ease;
}
.owl-theme:hover .owl-nav .disabled {
  opacity: 0.4 !important;
}

/*Audio Arrwos*/

.audioCarsoulArrowContainer .owl-theme {
  position: relative;
  padding: 0px 44px;
}
.audioCarsoulArrowContainer .owl-prev,
.audioCarsoulArrowContainer .owl-next {
  margin-top: -4px !important;
  opacity: 1 !important;
}
@media (max-width: 600px) {
  .audioCarsoulArrowContainer .owl-prev,
  .audioCarsoulArrowContainer .owl-next {
    margin-top: -18px !important;
  }
}
@media (max-width: 375px) {
  .audioCarsoulArrowContainer .owl-theme {
    padding: 0px 0px;
  }
}
.audioCarsoulArrowContainer .owl-theme .owl-nav [class*="owl-"] {
  width: 24px;
  height: 24px;
  display: block;
  z-index: 10;
  opacity: 1 !important;
}
.audioCarsoulArrowContainer .owl-theme:hover .owl-prev {
  right: 0 !important;
  opacity: 1 !important;
}
.audioCarsoulArrowContainer .owl-theme:hover .owl-next {
  left: 0 !important;
  opacity: 1 !important;
}

.audioCarsoulArrowContainer .owl-theme .owl-nav .disabled {
  opacity: 0.4 !important;
}

/*dots ----------------------------------------------------------*/

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 8px !important;
}
.owl-theme .owl-dots .owl-dot span {
  width: 7px !important;
  height: 7px !important;
  margin: 14px 3px 0px 3px !important;
  background: rgba(0, 0, 0, 0.2) !important;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #ff5722 !important;
}

/*/*/
@keyframes moveCloude {
  from {
    left: -54px;
  }
  to {
    left: 110%;
  }
}
@-webkit-keyframes moveCloude {
  from {
    left: -54px;
  }
  to {
    left: 110%;
  }
}

/*/*/
.animationIcon {
  position: relative;
  animation: protoTypeCursor 1.5s infinite alternate;
}
@keyframes protoTypeCursor {
  from {
    opacity: 0;
    margin-top: 100px;
  }
  to {
    opacity: 1;
    margin-top: 0px;
  }
}
@-webkit-keyframes protoTypeCursor {
  from {
    opacity: 0;
    margin-top: 100px;
  }
  to {
    opacity: 1;
    margin-top: 0px;
  }
}

/*/*/
.seSpace {
  background: #fff;
  height: 10px;
  margin: 18px 0px;
}

@media (max-width: 600px) {
  .seSpace {
    background-color: #e8eaf6;
    margin: 32px 0px;
  }
}

/*/*/

.coverCarouselContainer {
  position: relative;
}

.coverCarouselContainer .owl-prev {
  margin-top: -20px !important;
}
.coverCarouselContainer .owl-next {
  margin-top: -20px !important;
}

/*/*/

.animatedHand {
  position: absolute !important;
  top: 0;
  right: 0;
  z-index: 4;
  margin-top: 4px;
  color: #90a4ae;
  font-size: 1.8rem !important;
  animation: animatedHandMotion 1.4s infinite alternate;
}
@media (max-width: 600px) {
  .animatedHand {
    display: none !important;
  }
}

@keyframes animatedHandMotion {
  from {
    opacity: 0;
    margin-right: -70px;
  }
  to {
    opacity: 1;
    margin-right: -36px;
  }
}
@-webkit-keyframes animatedHandMotion {
  from {
    opacity: 0;
    margin-right: -70px;
  }
  to {
    opacity: 1;
    margin-right: -36px;
  }
}

/*/*/
