.mainNav {
  padding: 33px 0px;
  background-color: #fff;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  width: 100%;

  z-index: 10;
  -moz-transition: padding 0.3s ease;
  -webkit-transition: padding 0.3s ease;
  -o-transition: padding 0.3s ease;
  transition: padding 0.3s ease;

  @media (max-width: 600px) {
    padding: 20px 0px;
  }

  .navLinks {
    display: none;
    gap: 69px;

    a {
      color: "#000";
      font-size: 0.875rem;
      font-weight: 500;
      position: relative;
      padding: 0px;
      &:hover {
        background-color: #fff;
        color: #fb5b0f;
      }
      &::after {
        content: "";
        width: 31px;
        height: 1px;
        background-color: #fb5b0f;
        position: absolute;
        bottom: 0;
        left: 50%;
        display: none;
        transform: translateX(-50%);
      }
    }
    .active {
      color: #fb5b0f;
      &::after {
        display: block;
      }
    }
    @media (min-width: 1200px) {
      display: flex;
    }
  }
  .menuIcon {
    display: none;
    margin-left: 18px;
    @media (max-width: 1199px) {
      display: block;
    }
    @media (max-width: 340px) {
      display: none;
    }
  }
  .contactButton {
    @media (max-width: 600px) {
      display: none;
    }
  }

  .navContactIcon {
    display: none;
    @media (max-width: 600px) {
      display: block;
    }
  }
}

// Fixed Nav ----------------------------------------------
.fixedNav {
  padding: 12px 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;

  .navLinks {
    .active {
      color: #fb5b0f;
      &::after {
        display: none;
      }
    }
  }

  @media (max-width: 600px) {
    padding: 14px 0px 10px 0px;
  }
}

// Nav Clear
.navClear {
  height: 114px;

  @media (max-width: 600px) {
    height: 80px;
  }
}

// Mobile bottom menu container
.MobileMenuContauner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 4px;
}
